import { useEffect, useRef, useState } from 'react';
import { tippy } from '@tippyjs/react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

import { MdCheckCircle, MdDriveFileRenameOutline } from 'react-icons/md';
import { RiShareForwardLine } from 'react-icons/ri';

import {
  selectCurrentEntity,
  selectCurrentStep,
  selectCurrentSurvey,
  selectCurrentVersion,
  setCurrentStep,
  setLoading,
  setModal,
  setShareModal,
} from '../../reducers/app.reducer';
import { selectAccount, selectActionRights, selectActiveAccount, selectUser } from '../../reducers/account.reducer';
import { selectAllContentfulData } from '../../reducers/contentful.reducer';

import { StepQuestions } from '../form/StepQuestions';
import { ProgressBar } from '../progress-bar/ProgressBar';
import { Breadcrumb } from '../breadcrumb/Breadcrumb';

import {
  getSurveyStepFilledRequiredQuestions,
  getSurveyStepRequiredQuestions,
  getStepQuestions,
  getDetailedSurveyById,
  filterSharedSteps,
  getEntityTotalFillingPercentage,
  getFilledRequiredQuestions,
  getRequiredQuestions,
  isSubmissionDisabled,
} from '../../utils/utils';
import { paths } from '@routes/routes.constants';
import { COLORS, MODAL_TYPES } from '../../utils/constants';

export interface SurveyProps {}

const Survey = ({}: SurveyProps) => {
  const activeAccount = useSelector(selectActiveAccount);
  const currentUser = useSelector(selectUser);
  const currentEntity = useSelector(selectCurrentEntity);
  const currentSurvey = useSelector(selectCurrentSurvey);
  const currentVersion = useSelector(selectCurrentVersion);
  const currentStep = useSelector(selectCurrentStep);
  const account = useSelector(selectAccount);
  const actionRights = useSelector(selectActionRights);
  const contentfulData = useSelector(selectAllContentfulData);
  const dispatch = useDispatch<any>();
  const shareRef = useRef(null);
  const history = useHistory();
  const [tempAccount, setTempAccount] = useState(null);
  const [requiredQuestions, setRequiredQuestions] = useState(0);
  const [filledQuestions, setFilledQuestions] = useState(0);
  const [stepsCompletion, setStepsCompletion] = useState([]);

  const isFormReady = () => {
    return !!account && !!contentfulData.surveys && !!actionRights && !!currentSurvey && !!currentVersion && !!tempAccount;
  };

  useEffect(() => {
    if (!currentSurvey || !currentEntity) {
      history.push(paths.dashboardGroup);
      return;
    }
    (async () => {
      dispatch(setLoading(true));
      //setTempAccount for themes condtions checking
      setTempAccount(_.cloneDeep(activeAccount));
      await updateCompletions();
      if (currentEntity?.submission?.submitted || currentEntity?.submission?.validated) {
        return;
      }
      let isEntityComplete = await isEntityCompleted();
      if (isEntityComplete) {
        if (!isSubmissionDisabled(currentUser, currentEntity)) {
          dispatch(setModal({ show: true, type: MODAL_TYPES.SUBMIT_ENTITY }));
        }
      } else if (isSurveyVersionCompleted) {
        dispatch(setModal({ show: true, type: MODAL_TYPES.NEXT_UNFULFILLED_SURVEY }));
      }
    })();
  }, []);

  useEffect(() => {
    if (currentSurvey) {
      updateCounters();
    }
  }, [tempAccount, currentSurvey]);

  useEffect(() => {
    if (currentSurvey && currentEntity && currentVersion) {
      let themes = filterSharedSteps(currentSurvey.steps)?.filter(isStepConditionOK);
      dispatch(setCurrentStep({ id: themes[0].id, name: themes[0].name }));
      dispatch(setLoading(false));
    }
  }, [isFormReady()]);

  useEffect(() => {
    if (tempAccount) {
      const cloneTempAccount = _.cloneDeep(tempAccount);
      const targetEntity = cloneTempAccount.entities.find(entityItem => entityItem._id === currentEntity._id);
      targetEntity.responses = currentEntity.responses;
      setTempAccount(cloneTempAccount);
      (async () => {
        let detailedSurvey = await getDetailedSurveyById(currentSurvey?.id);
        let dataset = getTempAccountDataset(currentVersion.versionResponses);
        let filledRequiredQuestions = await getFilledRequiredQuestions(currentEntity, detailedSurvey, currentVersion, dataset);
        setFilledQuestions(filledRequiredQuestions);
        await updateCompletions();
      })();
    }
  }, [currentEntity]);

  useEffect(() => {
    tippy(shareRef.current, {
      content: 'Partager la catégorie',
      arrow: true,
      placement: 'right',
    });
  }, [shareRef.current]);

  const updateCounters = async () => {
    let detailedSurvey = await getDetailedSurveyById(currentSurvey?.id);
    let dataset = await getTempAccountDataset(currentVersion.versionResponses);
    let requiredQuestions = await getRequiredQuestions(currentEntity, detailedSurvey, currentVersion, dataset);
    let filledRequiredQuestions = await getFilledRequiredQuestions(currentEntity, detailedSurvey, currentVersion, dataset);
    setFilledQuestions(filledRequiredQuestions);
    setRequiredQuestions(requiredQuestions);
  };

  const isEntityCompleted = async () => (await getEntityTotalFillingPercentage(currentEntity)) === 100;

  const isSurveyVersionCompleted = currentVersion?.completion?.completionPercentage === 100;

  const isStepCompleted = async stepName => {
    let requiredQuestions = await getSurveyStepRequiredQuestions(currentEntity, currentSurvey, currentVersion, stepName);
    let filledQuestions = await getSurveyStepFilledRequiredQuestions(currentEntity, currentSurvey, currentVersion, stepName);

    return filledQuestions.amount === requiredQuestions.amount;
  };

  const updateCompletions = async () => {
    let completions = [];
    let updatedCompletions = filterSharedSteps(currentSurvey?.steps).map(async step => {
      let completed = await isStepCompleted(step.name);
      let conditionsOK = isStepConditionOK(step);
      if (conditionsOK && completed) {
        return step.name;
      }
    });
    completions = (await Promise.all(updatedCompletions)).filter(theme => !!theme);
    setStepsCompletion(completions);
  };

  const isStepEmpty = stepName => {
    let stepQuestions = getStepQuestions(currentSurvey, currentVersion, stepName, currentEntity);
    return stepQuestions.amount === 0;
  };

  const getStepIcon = step => {
    return stepsCompletion.includes(step.name) ? <MdCheckCircle /> : isStepEmpty(step.name) ? <Circle /> : <MdDriveFileRenameOutline />;
  };

  const removeTypoIcons = str => {
    const icons = ['1️⃣', '2️⃣', '3️⃣', '4️⃣', '5️⃣', '6️⃣', '7️⃣', '8️⃣', '9️⃣', '🔟'];
    icons.forEach(icon => {
      str = str.replace(icon, '');
    });
    return str;
  };

  const shareItem = step => {
    const { id, name } = step;
    dispatch(setShareModal({ type: 'surveySteps', id: id, label: name }));
    dispatch(setModal({ show: true, type: MODAL_TYPES.SHARE }));
  };

  const getTempAccountVersionResponses = () => {
    const entities = tempAccount?.entities;
    const targetEntity = entities.find(entityItem => entityItem._id === currentEntity._id);
    const versions = targetEntity?.responses?.find(entityResponse => entityResponse.surveyId === currentSurvey.id)?.versions;
    return versions.find(version => version.versionName === currentVersion.versionName)?.versionResponses;
  };

  const getTempAccountDataset = currentSurveyResponses => {
    let nonVersionableSurveyIds = contentfulData.surveys
      .filter(survey => !survey.versioning.enabled)
      .map(survey => {
        return survey.id;
      });
    const entities = tempAccount?.entities;
    const targetEntity = entities?.find(entityItem => entityItem._id === currentEntity._id);
    const nonVersionableSurveysWithoutCurrentSurvey = targetEntity?.responses
      .filter(survey => nonVersionableSurveyIds.includes(survey.surveyId))
      .filter(survey => survey.surveyId !== currentSurvey.id);
    let dataset = currentSurveyResponses ? currentSurveyResponses : {};
    nonVersionableSurveysWithoutCurrentSurvey?.forEach(survey => {
      if (survey.versions[0]?.versionResponses) {
        dataset = { ...dataset, ...survey.versions[0]?.versionResponses };
      }
    });
    return dataset;
  };

  const isStepConditionOK = (step, version = undefined) => {
    if (!step?.conditions?.length || tempAccount?.entities.length <= 0 || !isFormReady()) {
      return true;
    }

    const versionResponses = version || getTempAccountVersionResponses();
    const dataset = getTempAccountDataset(versionResponses);

    if (!dataset) {
      return false;
    }

    return step.conditions.every(condition => {
      if (condition.OR) {
        return condition.OR.some(({ key, value }) => {
          const currentResponse = dataset[key];
          return Array.isArray(currentResponse) ? currentResponse.includes(value) : currentResponse === value;
        });
      }

      const currentResponse = dataset[condition.key];
      return Array.isArray(currentResponse) ? currentResponse.includes(condition.value) : currentResponse === condition.value;
    });
  };

  return (
    <>
      {currentVersion && (
        <SurveyPageContainer>
          <ThemeContainer>
            <ThemeList>
              <ThemeTitle>Thèmes</ThemeTitle>
              {currentSurvey && (
                <ListContainer>
                  {filterSharedSteps(currentSurvey?.steps)?.map(step => {
                    return (
                      isStepConditionOK(step) && (
                        <Theme
                          selected={step.id === currentStep.id}
                          onClick={() => dispatch(setCurrentStep({ id: step.id, name: step.name }))}
                          key={step.id}
                          completed={stepsCompletion.includes(step.name)}>
                          <>
                            {getStepIcon(step)}
                            <span>{removeTypoIcons(step?.name)}</span>
                            {step.id === currentStep.id && !stepsCompletion[step.name] && (
                              <div className='share' ref={shareRef}>
                                <RiShareForwardLine
                                  onClick={() => {
                                    shareItem(step);
                                  }}
                                />
                              </div>
                            )}
                          </>
                        </Theme>
                      )
                    );
                  })}
                </ListContainer>
              )}
            </ThemeList>
          </ThemeContainer>
          <SurveyContainer>
            <SurveyTop>
              <TitleContainer>
                <Title>
                  {currentVersion?.versionName}
                  {currentVersion?.versionName !== currentSurvey?.name && ' - ' + currentSurvey?.name}
                </Title>
                <Breadcrumb
                  items={[
                    { name: 'Accueil', onClick: () => history.push(paths.dashboardGroup) },
                    {
                      name: currentEntity?.name,
                      onClick: () => history.push(paths.dashboardEntity),
                    },
                    { name: currentSurvey?.name },
                  ]}></Breadcrumb>
              </TitleContainer>
              <ProgressBar level={filledQuestions} max={requiredQuestions} label={'questions remplies'} />
            </SurveyTop>
            <QuestionsContainer>
              <StyledStepQuestions />
            </QuestionsContainer>
          </SurveyContainer>
        </SurveyPageContainer>
      )}
    </>
  );
};

export default Survey;

const ListContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  overflow-y: auto;
  padding-right: 10px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 5px;
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 2.6em;
  font-family: 'Nunito', sans-serif;
`;

const StyledStepQuestions = styled(StepQuestions)`
  flex: 2;
`;

const QuestionsContainer = styled.div`
  display: flex;
  padding: 20px 30px 20px 70px;
  height: calc(100% - 17rem);
  width: calc(100% - 100px);
`;

const SurveyTop = styled.div`
  height: 150px;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 70px;
  row-gap: 30px;
`;

const SurveyPageContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`;

const SurveyContainer = styled.div`
  // height: 100%;
  // background-color: red;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 40px;
`;

const ThemeContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  min-width: 300px;
  background-color: #f1f8fd;
  padding: 40px;
`;

const ThemeList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 100%;
`;

const ThemeTitle = styled.span`
  font-weight: 700;
  font-size: 2.6em;
  font-family: 'Nunito', sans-serif;
`;

const Theme = styled.div<{ selected: boolean; completed: boolean }>`
  background-color: white;
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 1.2em;
  font-weight: 400;
  font-family: 'Roboto', serif;
  display: flex;
  align-items: center;
  column-gap: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  height: 25px;

  ${({ completed }) =>
    completed &&
    `
    border: 1px solid transparent;
    background-color: ${COLORS.DarkGreen};
    color: white;
  `};

  ${({ selected }) =>
    selected &&
    `
    background-color: ${COLORS.NiceBlue};
    color: white;
  `};

  &:hover {
    background-color: ${COLORS.NiceBlue};
    color: white;
    cursor: pointer;
  }

  svg {
    width: 24px;
    height: 24px;
  }

  span {
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
  }

  .share {
    align-self: flex-start;
    opacity: 0.5;
    transition: opacity 0.3s;

    &:hover {
      opacity: 1;
    }
  }
`;

const Circle = styled.div`
  background-color: rgba(200, 200, 200, 1);
  border-radius: 50px;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  margin-right: 4px;
`;
