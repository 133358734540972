import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { FiArrowRight } from 'react-icons/fi';
import { MdCheckCircle, MdDriveFileRenameOutline } from 'react-icons/md';
import { AiOutlineSave } from 'react-icons/ai';

import { LanguageSwitcher } from '@components-new/molecules';

import { Logo } from '@components/logo/logo';
import { InputComponent } from '@components/form/InputComponent';
import Spinner from '@components/spinner/Spinner';

import { checkSubContractorSurveyStatus, fetchContentfulEntryById } from '@utils/api';
import { BUTTON_COLORS } from '@utils/constants';
import { setSessionValue } from '@utils/storage';
import { compareObjects } from '@utils/utils';

import { WIZARD_SUB_CONTRACTORS } from '../wizards.slides';
import { WizardSlideProps } from '../wizards.type';
import { Circle, WizardContainer, SideBar, LogoContainer, Separator, StepContainer, ContentContainer, StepTitle, Content, SurveyPageContainer, ThemeContainer, ThemeList, ThemeTitle, ListContainer, StyledInfos, SurveyContainer, SurveyTop, TitleContainer, Title, QuestionsContainer, SpinnerContainer, ActionButton, ErrorMessage, Slide, Theme, Step } from './Subcontractor_.style';

const WizardSubContractor = () => {
  const { i18n, t } = useTranslation();
  // define the slides list for the wizard
  const slideList = WIZARD_SUB_CONTRACTORS(t);

  const params: { subContractorId } = useParams();

  const [questionData, setQuestionData] = useState([]);
  const [currentStep, setCurrentStep] = useState(null);
  const [currentStepSurvey, setCurrentStepSurvey] = useState(null);

  const [values, setValues] = useState([]);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [responses, setResponses] = useState({});
  const [dbResponses, setDBResponses] = useState({});
  const [companyName, setCompanyName] = useState<string>('');
  const [finality, setFinality] = useState<string>('');
  const [accountId, setAccountId] = useState<string>('');
  const [entityId, setEntityId] = useState<string>('');
  const [subContractorName, setSubContractorName] = useState<string>('');
  const [responseVersionId, setResponseVersionId] = useState<string>('');

  useEffect(() => {
    let isSurveyAnswered: boolean = false;
    let showSurveySlide: boolean;
    let entryId: string;
    const { subContractorId } = params;

    (async () => {
      try {
        setLoading(true);
        if (subContractorId) {
          const result = await checkSubContractorSurveyStatus(subContractorId);
          if (!result) throw new Error('UNABLE_TO_FETCH_SUB_CONTRACTOR_SURVEY_STATUS');

          showSurveySlide = result?.showSurvey;
          isSurveyAnswered = result?.surveyStatus;
          entryId = result?.entryId;
          setSubContractorName(result?.name);
          setAccountId(result?.accountId);
          setEntityId(result?.entityId);
          setCompanyName(result?.companyName);
          setFinality(result?.finality);
          setResponses(result?.responses);
          setDBResponses(result?.responses);
          setResponseVersionId(result?.responseVersionId);
          if (result?.tempToken) setSessionValue('tempToken', result.tempToken);
        }
        if (isSurveyAnswered) {
          setCurrentStep(slideList.length);
        }
        if (!isSurveyAnswered && entryId) {
          const result = await fetchContentfulEntryById(entryId, i18n.language === 'en' ? 'en-GB' : i18n.language);
          setQuestionData(result?.steps);
          if (showSurveySlide) {
            setCurrentStepSurvey({ id: result?.steps[0].id, name: result?.steps[0].name });
            setCurrentStep(3);
          } else {
            setCurrentStepSurvey({ id: result?.steps[0].id, name: result?.steps[0].name });
            setCurrentStep(1);
          }
        }
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, []);

  const goToStep = nextStep => {
    let lastStep = nextStep > slideList.length;
    if (!lastStep) {
      setCurrentStep(nextStep);
    }
  };

  const isThemeConditionsOK = theme => {
    if (!theme?.conditions?.length) {
      return true;
    }
    const conditions = theme.conditions.some(block =>
      block.conditions.every(condition => {
        if (condition.OR) {
          // Vérifier que AU MOINS UNE des conditions OR est valide
          return condition.OR.some(({ key, value }) => {
            const currentResponse = responses ? responses[key] : undefined;
            if (currentResponse === undefined || currentResponse === null) return false;
            return Array.isArray(currentResponse) ? currentResponse.includes(value) : currentResponse === value;
          });
        } else {
          // Vérifier une condition simple (ET)
          const currentResponse = responses ? responses[condition.key] : undefined;
          if (currentResponse === undefined || currentResponse === null) return false;
          return Array.isArray(currentResponse) ? currentResponse.includes(condition.value) : currentResponse === condition.value;
        }
      })
    );
    return conditions;
  }

  const isStepConditionsOK = step => {
    if (!step?.conditions?.length) {
      return true;
    }
    let conditions = step.conditions.every(condition => {
      if (values.find(value => value.name === `step-${condition.step}` && value.value === condition.value)) {
        return true;
      } else {
        return false;
      }
    });
    return conditions;
  };

  const isSelected = step => {
    return step === currentStep;
  };

  const isStepValid = step => {
    let hasInput = slideList[step - 1].input;
    let hasInputs = slideList[step - 1].items;
    let isEmpty = values.find(value => value.name === `step-${step}`) === undefined;
    let isVisible = isStepConditionsOK(slideList.find(item => item.step === step));

    if (hasInputs?.length) {
      return hasInputs?.every(
        item =>
          responses?.hasOwnProperty(item.name) && responses[item.name] !== '' && responses[item.name] !== null && responses[item.name] !== undefined
      );
    }
    if (slideList[step - 1].hasQuestion) {
      return isSurveyCompleted();
    }
    return !hasInput || !isEmpty || !isVisible;
  };

  const isSelectable = targetStep => {
    if (targetStep === 1 || currentStep > targetStep || !isStepValid(targetStep - 1)) {
      return false;
    }
    return true;
  };

  const handleSetValue = event => {
    let newValues = [...values];
    let stepCopy = newValues.find(value => value.name === event.target.name);
    if (!stepCopy) {
      newValues.push({ name: event.target.name, value: event.target.value });
    } else {
      if (event.target.value === '') {
        newValues = newValues.filter(value => value.name !== event.target.name);
      } else {
        stepCopy.value = event.target.value;
      }
    }
    setValues(newValues);
  };

  const handleSelect = (name, value) => {
    let newValues = [...values];
    let stepCopy = newValues.find(value => value.name === name);
    if (!stepCopy) {
      newValues.push({ name, value });
    } else {
      if (value === '') {
        newValues = newValues.filter(value => value.name !== name);
      } else {
        stepCopy.value = value;
      }
    }
    setValues(newValues);
  };

  const getNextStep = () => {
    for (let step = currentStep; step < slideList.length; step++) {
      if (isStepConditionsOK(slideList[step])) return step + 1;
    }
    return currentStep + 1;
  };

  const getStepValue = step => {
    //TODO check default value for select input type
    return values.find(value => value.name === `step-${step}`)?.value;
  };

  const handleChangeResponses = event => {
    const { name, value } = event.target;
    setResponses(prevState => {
      if (value === null) {
        delete prevState[name];
        return {
          ...prevState,
        };
      }
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const isQuestionConditionOK = (question) => {
    if (!question?.conditions?.length) {
      return true;
    }

    const shouldDisplay = question.conditions.some(block => {
      return block.conditions.every(condition => {
        if (condition.OR) {
          // Vérifier que AU MOINS UNE des conditions OR est valide
          return condition.OR.some(({ key, value }) => {
            // const currentResponse = responses[key];
            const currentResponse = responses ? responses[key] : undefined;

            if (currentResponse === undefined || currentResponse === null) return false;
            return Array.isArray(currentResponse) ? currentResponse.includes(value) : currentResponse === value;
          });
        } else {
          const currentResponse = responses ? responses[condition.key] : undefined;
          if (currentResponse === undefined || currentResponse === null) return false;
          return Array.isArray(currentResponse) ? currentResponse.includes(condition.value) : currentResponse === condition.value;
        }
      })
    });
    return shouldDisplay;
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    setResponses(prevInput => ({
      ...prevInput,
      [name]: value,
    }));
  };

  const handleButtonClick = async (slideStep: WizardSlideProps, justSave: boolean = false) => {
    if (slideStep.buttonShouldSave) {
      setLoading(true);
      slideStep
        .saveFunction({
          accountId,
          subContractorId: params.subContractorId,
          responses,
          isSurveyCompleted: isSurveyCompleted(),
          responseVersionId,
        })
        .then(res => {
          setLoading(false);
          if (res.success) {
            if (justSave) {
              setDBResponses(responses);
            } else {
              goToStep(getNextStep());
            }
          }
        })
        .catch(err => {
          setLoading(false);
        });
    } else {
      goToStep(getNextStep());
    }
  };

  const isSurveyCompleted = () => {
    const displayedThemes = questionData.filter(theme => isThemeConditionsOK(theme))
    return (
      displayedThemes.every(theme => {
        return isSurveyStepCompleted(theme.id);
      })
    );
  };

  const isSurveyStepCompleted = stepId => {
    let stepQuestions = getSurveyStepQuestions(stepId);
    return stepQuestions.every(question => {
      const isConditionMet = isQuestionConditionOK(question)
      if (question.required && isConditionMet) return !!responses?.[question.name];
      return true;
    });
  };

  const getSurveyStepQuestions = stepId => {
    return questionData
      ?.find(step => step.id === stepId)
      ?.form.map(question => {
        return {
          name: question.name,
          id: question.id,
          required: question.required,
          conditions: question.conditions
        };
      });
  };

  const isSurveyStepEmpty = stepId => {
    return getSurveyStepQuestions(stepId)?.length === 0;
  };

  const getStepIcon = step => {
    return isSurveyStepCompleted(step.id) ? <MdCheckCircle /> : isSurveyStepEmpty(step.id) ? <Circle /> : <MdDriveFileRenameOutline />;
  };

  const isResponsesChanged = useMemo(() => {
    const object1 = _.omit(dbResponses, ['responsableEmail', 'responsablePosition', 'companyName']);
    const object2 = _.omit(responses, ['responsableEmail', 'responsablePosition', 'companyName']);
    const isThereChanges = compareObjects(object1, object2);
    return isThereChanges;
  }, [responses, dbResponses]);

  const handleMissingCompanyName = useCallback((content: string): string => {
    const replacements = {
      fr: {
        companyName: companyName ? companyName : 'nos clients,',
        prononce: companyName ? 'ses' : 'leurs',
        finality
      },
      en: {
        companyName: companyName ? `${companyName}'s` : 'our clients',
        prononce: companyName ? 'its' : 'their',
        finality
      }
    };

    const currentLang = replacements[i18n.language] || replacements.fr;

    return content
      .replace('{companyName}', currentLang.companyName)
      .replace('{prononce}', currentLang.prononce)
      .replace('{finality}', currentLang.finality);

  }, [i18n.language, companyName, finality]);

  return (
    <WizardContainer>
      <SideBar>
        <LogoContainer>
          <Logo whiteBg={true} />
          <Separator />
        </LogoContainer>

        <StepContainer>
          {slideList.map((item, idx) => {
            return (
              isStepConditionsOK(item) && (
                <Step key={idx} selected={isSelected(item.step)} isSelectable={currentStep === 1 ? false : isSelectable(item.step)}>
                  <FiArrowRight />
                  {item.label}
                </Step>
              )
            );
          })}
        </StepContainer>
      </SideBar>
      <ContentContainer>
        <LanguageSwitcher />
        {slideList.map((item, index) => {
          return (
            isStepConditionsOK(item) &&
            item.step === currentStep && (
              <Slide key={index} slide={item.step}>
                {item?.title && <StepTitle>{item.title}</StepTitle>}
                {item?.content && <Content>{handleMissingCompanyName(item.content)}</Content>}
                {item?.input && (
                  <InputComponent
                    name={`step-${item.step}`}
                    type={item.inputType}
                    sharable={false}
                    value={getStepValue(item.step)}
                    onChange={item.inputType === 'select' ? handleSelect : handleSetValue}
                    options={item.options}
                  />
                )}
                {item?.items?.map((elt, index) => {
                  return (
                    <InputComponent
                      key={index}
                      name={elt.name}
                      type={elt.inputType}
                      sharable={false}
                      value={responses?.[elt.name]}
                      onChange={item.inputType === 'select' ? handleSelect : handleInputChange}
                      options={item.options}
                      label={elt.inputLabel}
                      placeholder={elt.placeholder}
                    />
                  );
                })}
                {item.hasQuestion && (
                  <SurveyPageContainer>
                    <ThemeContainer>
                      <ThemeList>
                        <ThemeTitle>{t('common.themes')}</ThemeTitle>
                        <ListContainer>
                          {questionData?.map(theme => {
                            return (
                              isThemeConditionsOK(theme) && <Theme
                                key={theme.id}
                                selected={theme.id === currentStepSurvey?.id}
                                onClick={() => setCurrentStepSurvey({ id: theme.id, name: theme.name })}
                                completed={isSurveyStepCompleted(theme.id)}>
                                {getStepIcon(theme)}
                                <span>{theme?.name}</span>
                              </Theme>
                            );
                          })}
                        </ListContainer>
                        {isResponsesChanged && !isSurveyCompleted() && (
                          <StyledInfos
                            action={{ text: t('common.saveResponses'), onClick: () => handleButtonClick(item, true) }}
                            icon={AiOutlineSave}
                            text={t('common.haveChangesToSave')}
                          />
                        )}
                      </ThemeList>
                    </ThemeContainer>
                    <SurveyContainer>
                      <SurveyTop>
                        <TitleContainer>
                          <Title>{currentStepSurvey?.name}</Title>
                        </TitleContainer>
                      </SurveyTop>
                      <QuestionsContainer>
                        {questionData
                          ?.find(step => step.id === currentStepSurvey?.id)
                          ?.form?.map((question, index) => {
                            const isConditionsOK = isQuestionConditionOK(question);
                            if (!isConditionsOK && responses[question.name]) {
                              setResponses(prevState => {
                                delete prevState[question.name];
                                return {
                                  ...prevState,
                                };
                              });
                            }
                            return (
                              isConditionsOK && (
                                <InputComponent
                                  index={index}
                                  key={question.name + index}
                                  id={question.id}
                                  label={question.label}
                                  name={question.name}
                                  type={question.type}
                                  longAnswers={question.long}
                                  placeholder={question.placeholder}
                                  options={question?.options}
                                  value={responses[question.name]}
                                  onChange={handleChangeResponses}
                                  display={'true'}
                                  required={question.required}
                                  status={true}
                                  isSubQuestion={question.conditions?.length > 0}
                                  sharable={false}
                                  {...(question.type === 'upload' && {
                                    externalUpload: {
                                      accountId: accountId,
                                      entityId: entityId,
                                      path: `Audits/${subContractorName}`,
                                    },
                                  })}
                                />
                              )
                            );
                          })}
                      </QuestionsContainer>
                    </SurveyContainer>
                  </SurveyPageContainer>
                )}
                {item.showNextButton &&
                  (loading ? (
                    <SpinnerContainer slide={item.step}>
                      <Spinner color={BUTTON_COLORS.default} />
                    </SpinnerContainer>
                  ) : (
                    <ActionButton
                      label={item.buttonLabel}
                      slide={item.step}
                      onClick={() => handleButtonClick(item)}
                      disabled={!isStepValid(item.step) || error}
                    />
                  ))}
                {item.step === currentStep && error && <ErrorMessage>Une erreur est survenue. Veuillez réessayer ultérieurement.</ErrorMessage>}
              </Slide>
            )
          );
        })}
      </ContentContainer>
    </WizardContainer>
  );
};

export default WizardSubContractor;
