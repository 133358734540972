import React from 'react';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { InputComponent } from './InputComponent';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentEntity,
  selectCurrentStep,
  selectCurrentSurvey,
  selectCurrentVersion,
  selectDirty,
  selectDisplayDebug,
  setCurrentEntity,
  setDirty,
  setModal,
} from '../../reducers/app.reducer';
import { selectAccount, selectActionRights, selectActiveAccount, selectUser, updateEntityResponses } from '../../reducers/account.reducer';
import { selectAllContentfulData } from '../../reducers/contentful.reducer';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { ACCOUNT_TYPES, MODAL_TYPES } from '../../utils/constants';
import {
  getAuthorizedSurveysByAccount,
  getEntityTotalFillingPercentage,
  isLinkedToShareQuestion,
  isSubmissionDisabled,
  isSurveyDisabled,
} from '../../utils/utils';
import { useHistory } from 'react-router-dom';
import { paths } from '@routes/routes.constants';
import { Infos } from '../infos/Infos';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { AiOutlineSave } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';

dayjs.locale('fr');

export const StepQuestions = () => {
  const dispatch = useDispatch<any>();
  const history = useHistory();
  const currentSurvey = useSelector(selectCurrentSurvey);
  const currentStep = useSelector(selectCurrentStep);
  const displayDebug = useSelector(selectDisplayDebug);
  const currentEntity = useSelector(selectCurrentEntity);
  const account = useSelector(selectAccount);
  const contentfulData = useSelector(selectAllContentfulData);
  const actionRights = useSelector(selectActionRights);
  const currentVersion = useSelector(selectCurrentVersion);
  const activeAccount = useSelector(selectActiveAccount);
  const { register } = useForm();
  const [toolTip, setToolTip] = useState(null);
  const [tempAccount, setTempAccount] = useState(null);
  const [isolatedResponses, setIsolatedResponses] = useState({});
  const isDirty = useSelector(selectDirty);
  const currentUser = useSelector(selectUser);
  const { t } = useTranslation();
  const questionsSliderElt = useRef(null);
  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});

  const isFormReady = () => {
    return !!account && !!contentfulData.surveys && !!actionRights && !!currentSurvey && !!currentVersion && !!tempAccount;
  };

  const getTempAccountVersionResponses = () => {
    const entities = tempAccount?.entities;
    const targetEntity = entities.find(entityItem => entityItem._id === currentEntity._id);
    const versions = targetEntity?.responses?.find(entityResponse => entityResponse.surveyId === currentSurvey.id)?.versions;
    return versions.find(version => version.versionName === currentVersion.versionName)?.versionResponses;
  };

  const getTempAccountDataset = currentSurveyResponses => {
    let authorizedSurveys = getAuthorizedSurveysByAccount();
    let nonVersionableSurveyIds = authorizedSurveys
      .filter(survey => !survey.versioning.enabled)
      .map(survey => {
        return survey.id;
      });
    const entities = tempAccount?.entities;
    const targetEntity = entities.find(entityItem => entityItem._id === currentEntity._id);
    const nonVersionableSurveysWithoutCurrentSurvey = targetEntity.responses
      .filter(survey => nonVersionableSurveyIds.includes(survey.surveyId))
      .filter(survey => survey.surveyId !== currentSurvey.id)
      .filter(survey => !survey.disabled);
    let dataset = currentSurveyResponses ? currentSurveyResponses : {};
    nonVersionableSurveysWithoutCurrentSurvey.forEach(survey => {
      if (survey.versions[0]?.versionResponses) {
        dataset = { ...dataset, ...survey.versions[0]?.versionResponses };
      }
    });
    return dataset;
  };

  const isQuestionConditionOK = (question, version = false) => {
    if (!question?.conditions?.length || !(tempAccount?.entities.length > 0) || !isFormReady()) {
      return true;
    }

    const versionResponses = version || getTempAccountVersionResponses();
    const dataset = getTempAccountDataset(versionResponses);

    if (!dataset) {
      return false;
    }

    return question.conditions.some(block =>
      block.conditions.every(condition => {
        if (condition.OR) {
          // Vérifier que AU MOINS UNE des conditions OR est valide
          return condition.OR.some(({ key, value }) => {
            const currentResponse = dataset[key];
            return Array.isArray(currentResponse) ? currentResponse.includes(value) : currentResponse === value;
          });
        } else {
          // Vérifier une condition simple (ET)
          const currentResponse = dataset[condition.key];
          return Array.isArray(currentResponse) ? currentResponse.includes(condition.value) : currentResponse === condition.value;
        }
      })
    );
  };

  const validateAndUpdateErrors = (name: string, value: string, form: any[]) => {
    let error = null;
    const question = form?.find(q => q.name === name);

    if (question?.type === 'number') {
      error = validateFieldNumber(value, question.labelLength);
    }

    return error;
  };

  const updateEntities = async (event: React.ChangeEvent<HTMLInputElement>, save = false, loader = true) => {
    const { name, value } = event.target;
    let target = event.target;
    let accountCopy = _.cloneDeep(tempAccount);
    let entitiesCopy = accountCopy?.entities;
    let targetEntity = entitiesCopy.find(entity => entity.name === currentEntity.name);
    setIsolatedResponses({ ...isolatedResponses, [target.name]: target.value });

    const currentForm = currentSurvey?.steps?.find(step => step.id === currentStep.id)?.form;
    const error = validateAndUpdateErrors(name, value, currentForm);

    if (error) {
      setValidationErrors({ ...validationErrors, [name]: error });
    } else {
      const { [name]: omit, ...remainingErrors } = validationErrors;
      setValidationErrors(remainingErrors);
    }

    targetEntity?.responses?.forEach(entityResponse => {
      if (entityResponse.surveyId === currentSurvey.id) {
        entityResponse.versions = entityResponse.versions.map(version => {
          if (version.versionName === currentVersion.versionName) {
            let updatedVersion = {
              ...version,
              versionResponses: {
                ...version.versionResponses,
                [target.name]: target.value,
              },
            };
            currentSurvey.steps.forEach(step => {
              let stepForm = step.form;
              for (let questionName in stepForm) {
                let question = stepForm[questionName];

                if (!isQuestionConditionOK(question, updatedVersion.versionResponses)) {
                  delete updatedVersion.versionResponses?.[question.name];
                }
                if (
                  updatedVersion.versionResponses?.[question.name] === null ||
                  updatedVersion.versionResponses?.[question.name] === undefined ||
                  updatedVersion.versionResponses?.[questionName] === '' ||
                  updatedVersion.versionResponses?.[questionName]?.length === 0
                ) {
                  delete updatedVersion.versionResponses?.[question.name];
                }
                if (updatedVersion.versionResponses && Object.keys(updatedVersion.versionResponses).length === 0) {
                  delete updatedVersion.versionResponses;
                }
              }
            });

            if (!target.value) {
              delete updatedVersion.versionResponses?.[target.name];
            }

            return updatedVersion;
          }
          return version;
        });
      }
    });

    !_.isEqual(activeAccount, accountCopy) ? dispatch(setDirty(true)) : dispatch(setDirty(false));
    setTempAccount(accountCopy);
    dispatch(setCurrentEntity(targetEntity));
    save && (await saveResponses({ ...isolatedResponses, [target.name]: target.value }, loader));
  };

  const getEntityResponseByName = questionName => {
    let responses = getTempAccountVersionResponses();
    if (!responses) {
      return;
    }
    return responses[questionName];
  };

  const isEntityCompleted = async () => (await getEntityTotalFillingPercentage(currentEntity)) === 100;

  const isSurveyVersionCompleted = () => currentVersion.completion.completionPercentage === 100;

  const saveResponses = async (data, loader) => {
    await dispatch(
      updateEntityResponses({
        accountId: activeAccount._id,
        userId: currentUser._id,
        entityId: currentEntity._id,
        versionId: currentVersion._id,
        surveyId: currentSurvey.id,
        stepId: currentStep.id,
        responses: data ?? isolatedResponses,
        loader,
      })
    );

    dispatch(setDirty(false));
    setIsolatedResponses({});
    toast.info(t('common.savedAnswers'));
  };

  const isSubQuestion = question => {
    if (!question?.conditions?.length) {
      return false;
    }

    return question.conditions.some(condition => {
      if (condition.OR) {
        return condition.OR.some(({ key }) => currentSurvey?.steps?.some(step => step.form.some(q => q?.name === key && step.id === currentStep.id)));
      }

      return currentSurvey?.steps?.some(step => step.form.some(q => q?.name === condition.key && step.id === currentStep.id));
    });
  };

  useEffect(() => {
    questionsSliderElt.current?.scrollTo({ top: 0 });
  }, [currentStep]);

  useEffect(() => {
    if (!currentSurvey || !currentEntity || !currentVersion) {
      history.push(paths.dashboardEntity);
    }
    setTempAccount(_.cloneDeep(activeAccount));

    return () => {
      dispatch(setDirty(false));
    };
  }, []);

  useEffect(() => {
    setTempAccount(_.cloneDeep(activeAccount));
    (async () => {
      if (await isEntityCompleted()) {
        if (!isSubmissionDisabled(currentUser, currentEntity)) {
          dispatch(setModal({ show: true, type: MODAL_TYPES.SUBMIT_ENTITY }));
        }
      } else if (isSurveyVersionCompleted()) {
        dispatch(setModal({ show: true, type: MODAL_TYPES.NEXT_UNFULFILLED_SURVEY }));
      }
    })();
  }, [activeAccount]);

  const isModificationAllowed = () => (account.accountType === ACCOUNT_TYPES.ADMIN ? false : currentEntity.submission?.submitted === true);

  const isNumeric = str => /^\d+$/.test(str);

  const validateFieldNumber = (fieldValue, expectedLength) => {
    if (fieldValue && fieldValue !== 'NA') {
      if (!isNumeric(fieldValue)) {
        return t('common.mustBeNumber');
      }

      if (String(fieldValue).length !== expectedLength) {
        return t('common.invalidLength', { fieldName: 'field', expectedLength });
      }
    }

    return null;
  };

  const getEntryUrl = (entryId: string) => {
    return `https://app.contentful.com/spaces/5h3y0cofo0sv/environments/${process.env.REACT_APP_CONTENTFUL_TARGET_ENVIRONMENT}/entries/` + entryId;
  };

  return (
    <StepQuestionsContainer>
      {isFormReady() && (
        <QuestionsContainer ref={questionsSliderElt}>
          <FormWrapper>
            {currentSurvey?.steps
              ?.find(step => step.id === currentStep.id)
              ?.form?.map((question, index) => (
                <React.Fragment key={index}>
                  {displayDebug && isQuestionConditionOK(question) && (
                    <DebugContainer
                    // dark={darkMode}
                    >
                      {question.required && <span style={{ color: 'red' }}>REQUIRED</span>} ( <span>{question.name + ' : '}</span>
                      {getEntityResponseByName(question.name)} )
                      <SeeOnContentful target={'_blank'} href={getEntryUrl(question.id)}>
                        See on contentful
                      </SeeOnContentful>
                    </DebugContainer>
                  )}
                  {isQuestionConditionOK(question) && (
                    <InputComponent
                      locked={
                        !isLinkedToShareQuestion(question, currentSurvey.id, getTempAccountVersionResponses()) ||
                        isModificationAllowed() ||
                        (isSurveyDisabled(currentSurvey.id, false) && account.accountType !== ACCOUNT_TYPES.ADMIN)
                      }
                      index={index}
                      register={register}
                      key={question.name + index}
                      id={question.id}
                      label={question.label}
                      name={question.name}
                      type={question.type}
                      longAnswers={question.long}
                      placeholder={question.placeholder}
                      options={question?.options}
                      value={getEntityResponseByName(question.name)}
                      onChange={updateEntities}
                      error={question.type === 'number' && validateFieldNumber(getEntityResponseByName(question.name), question.labelLength)}
                      tooltip={question.tooltipContent}
                      // darkMode={darkMode}
                      display={'true'}
                      last={index === currentSurvey?.steps.find(step => step.id === currentStep.id)?.form.length - 1}
                      required={question.required}
                      onHover={() => setToolTip(question.tooltipContent)}
                      onLeave={() => setToolTip(null)}
                      status={true}
                      isSubQuestion={isSubQuestion(question)}
                    />
                  )}
                </React.Fragment>
              ))}
            <Spacer />
          </FormWrapper>
        </QuestionsContainer>
      )}
      <Right>
        {isDirty && isModificationAllowed && Object.keys(validationErrors).length === 0 && (
          <StyledInfos
            action={{ text: t('stepQuestions.saveAnswers'), onClick: () => saveResponses(undefined, true) }}
            icon={AiOutlineSave}
            text={t('stepQuestions.unsavedChanges')}
          />
        )}
        <InfosContainer>{toolTip && <Infos text={toolTip} />}</InfosContainer>
      </Right>
    </StepQuestionsContainer>
  );
};

const SeeOnContentful = styled.a`
  margin-left: 10px;
  padding: 3px 8px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  background-color: #0072ff;
  color: white;
  transition: background-color 0.2s;
  cursor: pointer;

  &:hover {
    background-color: #0050d4;
  }
`;

const StyledInfos = styled(Infos)`
  position: absolute;
  margin: 0 30px;
  bottom: 120px;
`;

const InfosContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  margin-top: 25px;
`;

const Spacer = styled.div`
  min-height: 30px;
`;

const StepQuestionsContainer = styled.div`
  display: flex;
  background-color: white;
  width: 100%;
`;

const QuestionsContainer = styled.div`
  overflow-y: scroll;
  flex: 5;

  ::-webkit-scrollbar {
    transition: width 0.2s;
    width: 3px;
    height: 6px;

    &:hover {
      width: 6px;
    }
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 50px;
  padding: 20px 20px 20px 10px;
  width: calc(100% - 40px);
  overflow-x: hidden;
  min-height: calc(100% - 40px);
`;

const Right = styled.div`
  flex: 1;
  padding: 20px 0 20px 30px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 120px);
  width: calc(100% - 30px);
  min-width: 340px;
  align-items: center;
`;

const DebugContainer = styled.div<{ dark?: boolean }>`
  margin-bottom: -40px;
  display: block;
  opacity: 0.7;
  color: ${({ dark }) => (dark ? '#7bed9f' : '#0072ff')};

  span {
    font-family: 'Baloo 2 SemiBold';
  }
`;
