import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { IoIosLink } from 'react-icons/io';
import { RxCross2 } from 'react-icons/rx';
import { RiErrorWarningLine } from 'react-icons/ri';
import { MdKeyboardArrowLeft, MdOutlineEmail } from 'react-icons/md';
import { FaRegUserCircle } from 'react-icons/fa';
import { BsFillPinFill } from 'react-icons/bs';
import { TbCircleDashedCheck, TbProgress } from 'react-icons/tb';

import { auditsStatus } from '@components-new/common/constants';
import { Chip, Container, Loader } from '@components-new/atoms';
import { SearchBar } from '@components-new/molecules';

import { Modal } from '@components/modal/Modal';
import { Button } from '@components/Button';
import { SharedItem } from '@components/collaborators/SharedItem';
import { FileExplorer } from '@components/file-explorer/FileExplorer';

import { updateSubContractorFinality, updateSubContractorReAuditFrequency, validateOrRefuseAudit } from '@api/audit';

import { normalizeString } from '@utils/utils';
import { LEGAL_OPS } from '@utils/constants';

import { formatSubcontractorQuestionAndResponses, getFinalityOptions } from '../AuditsAndInventory.utils';
import { finalities } from '../AuditsAndInventory.constants';
import {
  BackgroundResponse, ContactInfoModal, ContainerSubTitleModal,
  ResponseContainer, ResponseHeader, Span, TitleModal, Separator, LegalOpsElement,
  StyledInputComponent,
  RemindersTitle,
  RemindersWarning,
  RemindersBody,
  LabelReminder,
  ReminderChoice,
  ContainerConfirm,
  TextReturn,
  StatusModal,
  NoAnswers,
} from '../audit/Audit_.style';

import { ModalAuditProps } from './ModalAudit.types';

export const ModalAudit = ({
  view,
  selectedEntity,
  selectedFinality,
  setSelectedFinality,
  selectedSubContractorOrAction,
  activeAccount,
  currentAccount,
  displayModal,
  setDisplayModal,
  subContractorData,
  setSubContractorData,
  surveyData,
  emailReminder,
  setEmailReminder,
  fetchAudits,
  setInventory,
  setSelectedSubContractorOrAction
}: ModalAuditProps) => {
  const { t } = useTranslation();
  const [displayModalReminder, setDisplayModalReminder] = useState<boolean>(false);
  const [displayModalConfirm, setDisplayModalConfirm] = useState<string>(null);
  const [nextReminder, setNextReminder] = useState(null);
  const [showAvailableDocuments, setShowAvailableDocuments] = useState({ show: false, fileName: null });
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const subContractorStatus = view === 'inventory' ? selectedSubContractorOrAction?.status : selectedSubContractorOrAction?.subContractor?.status

  const ChipStatus = ({ subContractorStatus }) => {
    let color
    let backgroundColor
    let icon
    let text

    if (subContractorStatus === 'conforme') {
      color = '#00C6A1'
      backgroundColor = '#00C6A114'
      icon = TbCircleDashedCheck
      text = t('common.compliant')
    } else if (subContractorStatus === 'non_conforme') {
      color = '#FF746F'
      backgroundColor = '#FFF1F1'
      icon = RxCross2
      text = t('common.non_compliant')
    } else {
      if (view === 'inventory') {
        color = '#F49D1D'
        backgroundColor = '#F49D1D1A'
        icon = TbProgress
        text = t('inventory.ongoingAudit')
      } else if (selectedSubContractorOrAction?.status === 'completed' || selectedSubContractorOrAction?.status === 'verify') {
        color = '#3AA3CC'
        backgroundColor = '#EBF6FD'
        icon = TbCircleDashedCheck
        text = t('common.toVerify')
      }
      else {
        color = '#F49D1D'
        backgroundColor = '#F49D1D1A'
        icon = TbProgress
        text = view === 'inventory' ? t('inventory.ongoingAudit') : t('common.inProgress')
      }
    }
    return (
      <StatusModal color={color} backgroundColor={backgroundColor}>
        <Chip height='25px' icon={icon} iconSize={18} color={color} backgroundColor='transparent' />
        <Span>{text}</Span>
      </StatusModal>
    )
  }

  const handleChangeReminder = async () => {
    setLoading(true)
    const hasContactChanged = emailReminder !== (selectedSubContractorOrAction?.subContractor?.contactEmail || selectedSubContractorOrAction?.contactEmail)
    let result;
    if (hasContactChanged) {
      result = await updateSubContractorReAuditFrequency(currentAccount._id, selectedSubContractorOrAction?.subContractor?.id || selectedSubContractorOrAction?._id, nextReminder, emailReminder)
    } else {
      result = await updateSubContractorReAuditFrequency(currentAccount._id, selectedSubContractorOrAction?.subContractor?.id || selectedSubContractorOrAction?._id, nextReminder)
    }
    setNextReminder(null)
    setDisplayModalReminder(false)
    fetchAudits && fetchAudits()
    if (view === 'inventory' && result.success) {
      setSelectedSubContractorOrAction(result.data)
      setInventory((prevData) => {
        const subContractorIndex = prevData.findIndex((subContractor) => subContractor._id === result.data._id)
        prevData[subContractorIndex] = result.data
        return prevData
      });
    }
    setLoading(false)
  }

  const closeModal = () => {
    setNextReminder(null)
    setDisplayModalConfirm(null)
    setDisplayModal(false)
    setDisplayModalReminder(false)
    setSelectedSubContractorOrAction(null)
  }

  const confirmAudit = async (action: any, type: string) => {
    setLoading(true)
    setSubContractorData((prevData) => ({
      ...prevData,
      status: type === 'validate' ? 'conforme' : 'non_conforme',
    }));
    if (view === 'audit') {
      await validateOrRefuseAudit(currentAccount._id, selectedEntity._id, action.actionId, type, action.versionProductId, action.relatedProductId, action.subContractor.id)
    }
    fetchAudits();
    setDisplayModalConfirm(null)
    setDisplayModal(false)
    setLoading(false)
  }

  const updateFinality = async (subContractor, finality) => {
    await updateSubContractorFinality(view === 'inventory' ? subContractor?._id : subContractor?.subContractor?.id, finality.value)
    setSelectedFinality(finality)
    fetchAudits()
  }

  const REMINDER_CHOICES = [
    { value: 0, label: t('common.now') },
    { value: 3, label: t('common.inSomeMonths', { n: '3' }) },
    { value: 6, label: t('common.inSomeMonths', { n: '6' }) },
    { value: 9, label: t('common.inSomeMonths', { n: '9' }) },
    { value: 12, label: t('common.inSomeMonths', { n: '12' }) }
  ]

  return (
    <>
      <Modal size={{ height: (!displayModalReminder || loading) && '75%', width: '83%', maxHeight: '75%' }} show={displayModal} onClose={() => closeModal()} closeColor='#00000057'>
        {loading ? <Container flex justifyContent='center' height='100%' alignItems='center' >
          <Loader loaderOnly />
        </Container> : <>
          <Container flex alignItems='center' flexWrap='wrap'>
            <TitleModal>{selectedSubContractorOrAction?.subContractor?.name || selectedSubContractorOrAction?.companyName || selectedSubContractorOrAction?.clientGivenName}</TitleModal> <Separator />
            <ChipStatus subContractorStatus={subContractorStatus} />
            {subContractorData?.responsableEmail && <><Separator /><ContactInfoModal><MdOutlineEmail /> <Span>{subContractorData.responsableEmail}</Span></ContactInfoModal></>}
            {subContractorData?.responsablePosition && <><Separator /><ContactInfoModal><FaRegUserCircle /> <Span>{subContractorData.responsablePosition}</Span></ContactInfoModal></>}
            {LEGAL_OPS.includes(activeAccount._id) && <><Separator /><LegalOpsElement fontSize="1.2em"><BsFillPinFill size={12} /><span>{t('common.legalOps')}</span></LegalOpsElement></>}
            {LEGAL_OPS.includes(activeAccount._id) && selectedSubContractorOrAction?.surveyLink && <><Separator /><LegalOpsElement fontSize="1.2em"><a target='_blank' rel="noreferrer" href={selectedSubContractorOrAction?.surveyLink}><IoIosLink size={14} /><span>{t('common.edit')}</span></a></LegalOpsElement></>}
          </Container>
          <ContainerSubTitleModal>
            {LEGAL_OPS.includes(activeAccount._id) ?
              <Container width='620px'>
                <StyledInputComponent
                  height='40px'
                  placeholder={t('common.finality')}
                  type={'select'}
                  options={getFinalityOptions(finalities)}
                  onChange={(_value: any, _field: any, _event: any, option: { label: string; value: string }) => updateFinality(selectedSubContractorOrAction, option)}
                  value={selectedFinality}
                  sharable={false}
                  noOptionsMessage={() => t('actionPlan.no_entity_available')}
                  roundedBorder='17px'
                  fontFamily='Nunito'
                />
              </Container>
              : <Span>{selectedSubContractorOrAction?.finality}</Span>
            }
            {((view !== 'inventory' && (selectedSubContractorOrAction?.status === 'completed' || selectedSubContractorOrAction?.status === 'verify')) && !displayModalReminder) &&
              <Container flex justifyContent='flex-end' gap='21px'>
                {subContractorStatus === 'ongoing' && selectedEntity &&
                  <>
                    <Button fontFamily='Nunito' label={t('common.refuse')} icon={RxCross2} customColor='#FF746F' padding='7px 25px' labelOptions={{ fontSize: '1em' }} onClick={() => { setDisplayModalConfirm('refuse') }} />
                    <Button fontFamily='Nunito' label={t('common.validate')} icon={auditsStatus.completed.icon} customColor='#326C82' padding='7px 25px' labelOptions={{ fontSize: '1em' }} onClick={() => { setDisplayModalConfirm('validate') }} />
                  </>
                }
              </Container>}
          </ContainerSubTitleModal>
          <BackgroundResponse display={(view === 'inventory' || selectedSubContractorOrAction?.status === 'completed' || selectedSubContractorOrAction?.status === 'verify') ? 'grid' : 'block'}>
            {displayModalReminder ?
              <Container p={'10px 20px'}>
                <RemindersTitle>{t('audits.autoReminders')} :</RemindersTitle>
                <RemindersWarning>
                  <RiErrorWarningLine size={21} />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('audits.reminderWarning'),
                    }} />
                </RemindersWarning>
                <RemindersBody>
                  <LabelReminder fw='600'>
                    {t('audits.nextReminder')} : {' '}
                    {nextReminder !== null ?
                      <>
                        <span style={{ textDecoration: 'line-through' }}>{dayjs(selectedSubContractorOrAction.lastAuditDate).add(selectedSubContractorOrAction.reminderFreq, 'month').format('DD.MM.YYYY')}</span>
                        {' '}<span>{dayjs(selectedSubContractorOrAction.lastAuditDate).add(nextReminder, 'month').format('DD.MM.YYYY')}</span>
                      </>
                      :
                      <span>{dayjs(selectedSubContractorOrAction.lastAuditDate).add(selectedSubContractorOrAction.reminderFreq, 'month').format('DD.MM.YYYY')}</span>
                    }
                  </LabelReminder>
                  <Container flex column gap='12px'>
                    <LabelReminder fw='500'>{t('audits.intermediateReminder')} :</LabelReminder>
                    <ReminderChoice>
                      {REMINDER_CHOICES.map(option => (
                        <label htmlFor={`reminder_${option.value}`} key={option.value}>
                          <input
                            name="reminder"
                            onChange={() => setNextReminder(option.value)}
                            type="radio"
                            id={`reminder_${option.value}`}
                            checked={nextReminder === option.value}
                          />
                          {option.label}
                        </label>
                      ))}
                    </ReminderChoice>
                  </Container>
                  <Container flex column gap='12px' mb='20px'>
                    <LabelReminder fw='500'>{t('audits.enterMailReminder')} :</LabelReminder>
                    <Container width='350px'>
                      <StyledInputComponent type='text' name={'emailReminder'} height='30px' roundedBorder='25px' clearable='true' value={emailReminder} setValue={setEmailReminder} onChange={event => setEmailReminder(event.target.value)} />
                    </Container>
                  </Container>
                </RemindersBody>
                <Container flex justifyContent='space-between' alignItems='center' mt='13px'>
                  <Container flex alignItems='center'>
                    <MdKeyboardArrowLeft color='#326C82' size='20px' />
                    <TextReturn onClick={() => { setDisplayModalReminder(false) }}>{t('common.cancel').toUpperCase()}</TextReturn>
                  </Container>
                  <Button
                    customColor='#326C82'
                    label={t('common.validate')}
                    fontFamily='Nunito'
                    labelOptions={{ fontWeight: '700', fontSize: '1em' }}
                    style={{ 'width': '15%' }}
                    onClick={() => { handleChangeReminder() }}
                  />
                </Container>
              </Container>
              : displayModalConfirm ?
                <ContainerConfirm>
                  <p dangerouslySetInnerHTML={{
                    __html: displayModalConfirm === 'validate' ? t('audits.confirmAudit', { name: selectedSubContractorOrAction?.subContractor?.name || selectedSubContractorOrAction?.companyName || selectedSubContractorOrAction?.clientGivenName }) : t('audits.refuseAudit', { name: selectedSubContractorOrAction?.subContractor?.name || selectedSubContractorOrAction?.companyName || selectedSubContractorOrAction?.clientGivenName }),
                  }} />
                  <Container flex justifyContent='center' mt='13px' width='100%'>
                    <Container flex column alignItems='center' width='100%'>
                      <Button style={{ 'width': '25%' }} fontFamily='Nunito' label={t(`common.${displayModalConfirm}`)} icon={displayModalConfirm === 'validate' ? auditsStatus.completed.icon : RxCross2} customColor={displayModalConfirm === 'validate' ? '#326C82' : '#FF746F'} padding='7px 25px' labelOptions={{ fontSize: '1em' }} onClick={() => { confirmAudit(selectedSubContractorOrAction, displayModalConfirm) }} />
                      <TextReturn style={{ 'marginTop': '20px' }} onClick={() => { setDisplayModalConfirm(null) }}>{t('common.cancel').toUpperCase()}</TextReturn>
                    </Container>
                  </Container>
                </ContainerConfirm>
                :
                <>
                  <ResponseHeader>
                    <SearchBar placeholder={t('common.search')} query={searchQuery} setQuery={setSearchQuery} colors={{ background: 'white' }} roundedBorder={true} />
                    <a onClick={() => setDisplayModalReminder(true)}>{t('audits.changeReminders').toUpperCase()}</a>
                  </ResponseHeader>
                  {(view === 'inventory' || selectedSubContractorOrAction?.status === 'completed' || selectedSubContractorOrAction?.status === 'verify') ?
                    <ResponseContainer>
                      {subContractorData?.responses &&
                        Object.entries(subContractorData.responses)
                          .map(([key, value]) => {
                            // Extract data outside conditional rendering
                            const { questionLabel, response } = formatSubcontractorQuestionAndResponses(key, value, surveyData);
                            const normalizedQuery = searchQuery ? normalizeString(searchQuery.toLowerCase().trim()) : '';
                            const normalizedQuestion = normalizeString(questionLabel.toLowerCase());
                            const normalizedResponse = normalizeString(response.toLowerCase());
                            const isFile = typeof value === 'string' && /\.(pdf|docx|xlsx)$/i.test(value);

                            // Filter based on search query
                            if (!searchQuery ||
                              normalizedQuestion.includes(normalizedQuery) ||
                              normalizedResponse.includes(normalizedQuery)) {
                              return (
                                <SharedItem
                                  questionValue={response}
                                  value={questionLabel}
                                  valid={true}
                                  key={key}
                                  type='questions'
                                  cursor={isFile ? 'pointer' : null}
                                  onClickFn={() => isFile && setShowAvailableDocuments({ show: true, fileName: value })}
                                  backgroundItems='white'
                                />
                              );
                            }

                            return null;
                          })
                          .filter(Boolean)
                      }
                    </ResponseContainer>
                    :
                    selectedSubContractorOrAction?.status === 'ongoing' ?
                      <NoAnswers>{t('audits.noAnswers')} <span>🥹</span></NoAnswers> :
                      <></>
                  }
                </>}
          </BackgroundResponse>
        </>}
      </Modal >
      {selectedEntity && showAvailableDocuments.show && selectedSubContractorOrAction && (
        <Modal
          size={{ height: '100%' }}
          show={showAvailableDocuments?.show}
          title={t('actionPlan.available_documents')}
          onClose={() => setShowAvailableDocuments({ show: false, fileName: null })}>
          <FileExplorer
            admin={true}
            selectedEntity={selectedEntity}
            selectedFileKey={`public/${currentAccount._id}/${selectedEntity._id}/Audits/${selectedSubContractorOrAction?.subContractor?.name || selectedSubContractorOrAction?.clientGivenName}/${showAvailableDocuments.fileName}`}
            showEntitySelection={false}
            disableBreadcrumb
          />
        </Modal>
      )}
    </>
  )
}

export default ModalAudit;